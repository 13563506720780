const packageJson = require("/package.json");

const GROUP_SOURCE = "shizuokaShohanShinrinbo5JouSource";
const GROUP_SOURCE_LAYER = "MAGIS.SHOHAN_SHINRINBO.5JOU";
const GROUP_MIN_ZOOM = 12.5;
const GROUP_MAX_ZOOM = 20;
const GROUP_BASE_OPACITY = 0.5;

// 小班森林簿 / 森の力 再生事業対象地レイヤー群
const layers = [];

layers.push(
  {
    id: "shizuoka-shinrinbo-森の力-再生事業対象地",
    type: "fill",
    source: GROUP_SOURCE,
    "source-layer": GROUP_SOURCE_LAYER,
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    filter: [
      "in",
      ['get', '新特定施業森林CD'],
      ["literal", ["6", "7"]]
    ],
    paint: {
      "fill-color": "#00DDDD"
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-森の力-再生事業対象地（6,7以外）",
    type: "fill",
    source: GROUP_SOURCE,
    "source-layer": GROUP_SOURCE_LAYER,
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    filter: [
      "in",
      ['get', '新特定施業森林CD'],
      ["literal", ["0", "1", "2", "3", "4", "5", "8", "9"]]
    ],
    paint: {
      "fill-color": "#FF0000"
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
);

const shizuokaShinrinboLayers_MoriNoChikaraRaster = {
  layers: layers
};

export default shizuokaShinrinboLayers_MoriNoChikaraRaster;
