import Vue from "vue";
import VueRouter from "vue-router";
import AgreementStore from "./store/agreementModule";
import TopPage from "./views/TopPage";
import Management from "./views/Management";

const packageJson = require("/package.json");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import(/* webpackChunkName: "map" */ "./views/Map.vue")
  },
  {
    path: "/top",
    name: "TopPage",
    component: TopPage,
    meta: { isPublic: true }
  },
  {
    path: "/map",
    name: "Map",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "map" */ "./views/Map.vue")
  },
  {
    path: "/management",
    name: "Management",
    component: Management,
    meta: { isPublic: true }
  },
  {
    path: "/management/Information",
    name: "ManagementInformation",
    component: () => import(/* webpackChunkName: "managementInformation" */ "./views/InformationManagement.vue"),
    meta: { isPublic: true }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  console.log(`... license  = ${AgreementStore.state.isLicenseAgreed}`);

  if (
    to.matched.some(record => record.meta.isPublic) ||
    (AgreementStore.state.isLicenseAgreed)
  ) {
    console.log(`router to:fullPath: ${to.fullPath}`);
    next();
  } else {
      next({
        path: "/top",
        query: { redirect: to.fullPath, version: packageJson.version }
      });
  }
});

export default router;
