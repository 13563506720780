const packageJson = require('/package.json');

const GROUP_ENDPOINT = process.env.VUE_APP_SHIZUOKA_RASTER_ENDPOINT + "/MAGIS.SHOHAN_SHINRINBO.5JOU.林種.";
const GROUP_SOURCE = "shizuokaShohanShinrinbo5Jou_Rinshu_";
const GROUP_MIN_ZOOM = 7;
const GROUP_MAX_ZOOM = 12.5;
const GROUP_BASE_OPACITY = 0.5;

// 林種（ラスター）レイヤー群
const layers = [];

layers.push(
  {
    id: "shizuoka-shinrinbo-林種-raster-人工林",
    type: "raster",
    source: GROUP_SOURCE + "人工林",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    metadata: {
      source: {
        type: "raster",
        tiles: [
          GROUP_ENDPOINT + "人工林" + "/{z}/{x}/{y}.png" +
          "?" + packageJson.lastupdate
        ],
        minzoom: 4,
        maxzoom: 13,
        tileSize: 256
      },
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-林種-raster-天然林",
    type: "raster",
    source: GROUP_SOURCE + "天然林",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    metadata: {
      source: {
        type: "raster",
        tiles: [
          GROUP_ENDPOINT + "天然林" + "/{z}/{x}/{y}.png" +
          "?" + packageJson.lastupdate
        ],
        minzoom: 4,
        maxzoom: 13,
        tileSize: 256
      },
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-林種-raster-竹林",
    type: "raster",
    source: GROUP_SOURCE + "竹林",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    metadata: {
      source: {
        type: "raster",
        tiles: [
          GROUP_ENDPOINT + "竹林" + "/{z}/{x}/{y}.png" +
          "?" + packageJson.lastupdate
        ],
        minzoom: 4,
        maxzoom: 13,
        tileSize: 256
      },
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-林種-raster-伐採跡地等",
    type: "raster",
    source: GROUP_SOURCE + "伐採跡地等",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    metadata: {
      source: {
        type: "raster",
        tiles: [
          GROUP_ENDPOINT + "伐採跡地等" + "/{z}/{x}/{y}.png" +
          "?" + packageJson.lastupdate
        ],
        minzoom: 4,
        maxzoom: 13,
        tileSize: 256
      },
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
);

const shizuokaShinrinboLayers_RinshuRaster = {
  layers: layers
};

export default shizuokaShinrinboLayers_RinshuRaster;
