export default {
  namespaced: true,
  state: {
    isLicenseAgreed: false,
  },
  mutations: {
    storeIsLicenseAgreed(state, payload) {
      state.isLicenseAgreed = payload.isAgreed;
    }
  }
};
