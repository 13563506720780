const packageJson = require('/package.json');

const GROUP_MIN_ZOOM = 7.5;
const GROUP_MAX_ZOOM = 20;
const GROUP_BASE_OPACITY = 0.5;

// 経営計画 レイヤー一覧
const layers = [];

layers.push(
  // 経営計画 - 森林経営計画作成箇所 ポリゴン
  {
    id: "shizuoka-経営計画-森林経営計画作成箇所",
    type: "fill",
    source: "shizuokaKeieiKeikakuSource",
    "source-layer": "SHINRIN_KEIEI_KEIKAKU_SAKUSEI_KASHO",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    paint: {
      "fill-color": "#FFAA7F",
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  // 経営計画 - 森林経営計画作成箇所(ハイライト) ポリゴン
  {
    id: "shizuoka-経営計画-森林経営計画作成箇所-hilight",
    type: "fill",
    source: "shizuokaKeieiKeikakuSource",
    "source-layer": "SHINRIN_KEIEI_KEIKAKU_SAKUSEI_KASHO",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    filter: [
      "all",
      false,
    ],
    paint: {
      "fill-color": "rgba(255, 255, 0, 0.4)",
    },
    layout: {
      visibility: "none"
    },
    metadata: {
      baseOpacity: 0.4
    }
  }
);

const tilesPath =
    process.env.VUE_APP_SHIZUOKA_MVT_ENDPOINT +
    "/SHINRIN_KEIEI_KEIKAKU_SAKUSEI_KASHO/{z}/{x}/{y}.pbf" +
    "?" + packageJson.lastupdate

const shizuokaKeieiKeikaku = {
  layers: layers,
  source: {
    type: "vector",
    tiles: [tilesPath],
    minzoom: 4,
    maxzoom: 18
  }
};

export default shizuokaKeieiKeikaku;
