export default {
  namespaced: true,
  state: {
    userId: "",
    isLogin: false,
    token: ""
  },
  mutations: {
    storeUserId(state, payload) {
      state.userId = payload.userId;
    },
    storeIsLogin(state, payload) {
      state.userId = payload.isLogin;
    },
    storeToken(state, payload) {
      state.token = payload.token;
    }
  }
};
