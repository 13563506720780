<template>
  <b-container class="container-div">
    <b-card class="login-wrap">
      <h5>静岡県森林クラウド公開システム</h5>
      <div class="version">
        Version: {{ this.$store.getters["const/clientVersion"] }}
      </div>
      <div class="version">
        Update: {{ this.$store.getters["const/lastUpdate"] }}
      </div>
      <div class="version">
        {{ this.$store.getters["const/envMode"] }}
      </div>
      <hr style="width: 16em" />

      <b-form v-on:submit.prevent="login()">
        <div class="form-group mb-3">
          <div class="input-group">
            <div class="input-group-prepend mr-1 mt-2">
              <i class="material-icons text-black-secondary">
                account_circle
              </i>
            </div>
            <input
              type="text"
              v-model="userId"
              class="form-control"
              placeholder="ユーザーID"
              autocomplete="username"
              @focus="onFocus"
              @blur="onBlur"
            />
          </div>
        </div>

        <div class="form-group mb-3">
          <div class="input-group">
            <div class="input-group-prepend mr-1 mt-2">
              <i class="material-icons text-black-secondary"> lock </i>
            </div>
            <input
              type="password"
              v-model="password"
              class="form-control"
              placeholder="パスワード"
              autocomplete="current-password"
            />
          </div>
        </div>

        <div style="margin-top: 10px">
          <b-button
            type="submit"
            class="action-button"
            id="login-production"
            variant="primary"
            style="background: #ff7900"
          >
            ログイン
          </b-button>
        </div>
      </b-form>

      <div v-if="invalid" class="invalid-message">
        ユーザーIDまたはパスワードが<br />正しくありません。
      </div>
    </b-card>
  </b-container>
</template>

<style scoped>
.container-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2em;
  padding-bottom: 2em;
  height: inherit;
}
.login-wrap {
  width: fit-content;
  margin: auto;
}
.version {
  text-align: center;
  font-size: 12px;
  color: gray;
}
.invalid-message {
  margin-top: 1rem;
  color: red;
  font-size: 16px;
}
.action-button {
  border: 0px;
  font-size: 1rem;
  margin-right: 1px;
  margin-left: 1px;
  padding: 4px 0 3px 0 !important;
  width: 8rem;
  height: 3rem;
}
.material-icons {
  width: 24;
  height: 30;
}
</style>

<script>
import router from "../../router";
import axios from "axios";

export default {
  name: "Management",
  data() {
    return {
      userId: "",
      password: "",
      showPassword: false,
      invalid: false
    };
  },
  mounted() {
    if (localStorage.userId) {
      this.userId = localStorage.userId;
    }
  },
  methods: {
    onFocus: function () {
      if (this.userId == "") {
        // this.userId = "AE";
      }
    },
    onBlur: function () {
      if (this.userId == "AE") {
        this.userId = "";
      }
    },
    login: function () {
      localStorage.userId = this.userId;
      var endpoint = "";

      // リダイレクト先のAPIアドレスを変数に設定
      endpoint = this.$store.getters["const/apiEndpoint"];
      endpoint += "/v1/management/account/Login/";

      axios
        .post(endpoint, {
          UserId: this.userId,
          Password: this.password
        })
        .then(res => {
          this.invalid = false;
          const token = res.data.token;
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          this.$store.commit("managementAuth/storeUserId", {
            userId: this.userId
          });
          this.$store.commit("managementAuth/storeIsLogin", {
            isLogin: true
          });
          this.$store.commit("managementAuth/storeToken", {
            token: token
          });

          axios.defaults.headers.common["Authorization"] = "Bearer " + token;

          router.push("/management/Information").catch(failure => {
            console.log(failure);
          });
        })
        .catch(() => {
          this.password = "";
          this.invalid = true;
        });
    }
  }
};
</script>
