const packageJson = require('/package.json');

const GROUP_ENDPOINT = process.env.VUE_APP_SHIZUOKA_RASTER_ENDPOINT + "/MAGIS.SHOHAN_SHINRINBO.5JOU.施業種.";
const GROUP_SOURCE = "shizuokaShohanShinrinbo5Jou_Segyoushu_";
const GROUP_MIN_ZOOM = 7;
const GROUP_MAX_ZOOM = 12.5;
const GROUP_BASE_OPACITY = 0.5;

// 施業種（ラスター）レイヤー群
const layers = [];

layers.push(
  {
    id: "shizuoka-shinrinbo-施業種-raster-通常伐期",
    type: "raster",
    source: GROUP_SOURCE + "通常伐期",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    metadata: {
      source: {
        type: "raster",
        tiles: [
          GROUP_ENDPOINT + "通常伐期" + "/{z}/{x}/{y}.png" +
          "?" + packageJson.lastupdate
        ],
        minzoom: 4,
        maxzoom: 13,
        tileSize: 256
      },
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-施業種-raster-伐期の延長",
    type: "raster",
    source: GROUP_SOURCE + "伐期の延長",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    metadata: {
      source: {
        type: "raster",
        tiles: [
          GROUP_ENDPOINT + "伐期の延長" + "/{z}/{x}/{y}.png" +
          "?" + packageJson.lastupdate
        ],
        minzoom: 4,
        maxzoom: 13,
        tileSize: 256
      },
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-施業種-raster-長伐期",
    type: "raster",
    source: GROUP_SOURCE + "長伐期",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    metadata: {
      source: {
        type: "raster",
        tiles: [
          GROUP_ENDPOINT + "長伐期" + "/{z}/{x}/{y}.png" +
          "?" + packageJson.lastupdate
        ],
        minzoom: 4,
        maxzoom: 13,
        tileSize: 256
      },
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-施業種-raster-複層林",
    type: "raster",
    source: GROUP_SOURCE + "複層林",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    metadata: {
      source: {
        type: "raster",
        tiles: [
          GROUP_ENDPOINT + "複層林" + "/{z}/{x}/{y}.png" +
          "?" + packageJson.lastupdate
        ],
        minzoom: 4,
        maxzoom: 13,
        tileSize: 256
      },
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-施業種-raster-複層林（択伐）",
    type: "raster",
    source: GROUP_SOURCE + "複層林_択伐",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    metadata: {
      source: {
        type: "raster",
        tiles: [
          GROUP_ENDPOINT + "複層林（択伐）" + "/{z}/{x}/{y}.png" +
          "?" + packageJson.lastupdate
        ],
        minzoom: 4,
        maxzoom: 13,
        tileSize: 256
      },
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-施業種-raster-特定広葉樹",
    type: "raster",
    source: GROUP_SOURCE + "特定広葉樹",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    metadata: {
      source: {
        type: "raster",
        tiles: [
          GROUP_ENDPOINT + "特定広葉樹" + "/{z}/{x}/{y}.png" +
          "?" + packageJson.lastupdate
        ],
        minzoom: 4,
        maxzoom: 13,
        tileSize: 256
      },
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
);

const shizuokaShinrinboLayers_SegyoushuRaster = {
  layers: layers
};

export default shizuokaShinrinboLayers_SegyoushuRaster;
