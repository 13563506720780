const packageJson = require('/package.json');

const GROUP_MIN_ZOOM = 7.5;
const GROUP_MAX_ZOOM = 20;

// 林道レイヤー一覧
const layers = [];

layers.push(
  // ライン(太)
  {
    id: "shizuoka-rindo-halo",
    type: "line",
    source: "shizuokaRindoSource",
    "source-layer": "MAGIS.RINDO",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    paint: {
      "line-color": "black",
      "line-width": [
        "step",
        ["zoom"],
        2.5,        // Default
        9.5, 2.5,   // Zoom level threshold & value after the threshold
        10.5, 6.0,
        12.0, 8.0,
        20.0, 8.0
      ]
    },
    metadata: {
      baseOpacity: 0.5
    }
  },
  // ライン(選択時ハイライト)
  {
    id: "shizuoka-rindo-hilight",
    type: "line",
    source: "shizuokaRindoSource",
    "source-layer": "MAGIS.RINDO",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    filter: [
      "all",
      false
    ],
    paint: {
      "line-color": "rgba(255, 255, 0, 0.4)",
      "line-width": [
        "step",
        ["zoom"],
        8.0,         // Default
        9.5, 8.0,    // Zoom level threshold & value after the threshold
        10.5, 14.0,
        12.0, 20.0,
        20.0, 20.0
      ]
    },
    layout: {
      visibility: "none"
    },
    metadata: {
      baseOpacity: 0.5
    }
  },

    // ライン(細)
  {
    id: "shizuoka-rindo",
    type: "line",
    source: "shizuokaRindoSource",
    "source-layer": "MAGIS.RINDO",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    paint: {
      "line-color": "#00CC00",
      "line-width": [
        "step",
        ["zoom"],
        2.0,         // Default
        9.5, 2.0,    // Zoom level threshold & value after the threshold
        10.5, 4.0,
        12.0, 6.0,
        20.0, 6.0
      ]
    },
    metadata: {
      baseOpacity: 0.7
    }
  },
);

const tilesPath =
    process.env.VUE_APP_SHIZUOKA_MVT_ENDPOINT +
    "/MAGIS.RINDO/{z}/{x}/{y}.pbf" +
    "?" + packageJson.lastupdate

const shizuokaRindo = {
  layers: layers,
  source: {
    type: "vector",
    tiles: [tilesPath],
    minzoom: 4,
    maxzoom: 18
  }
};

export default shizuokaRindo;
