import shizuokaCityEdge from "./sourceAndLayer/shizuokaCityEdge";
import shizuokaRinpan from "./sourceAndLayer/shizuokaRinpan";
import shizuokaJunRinpan from "./sourceAndLayer/shizuokaJunRinpan";
import shizuokaShohan from "./sourceAndLayer/shizuokaShohan";
import shizuokaMegane from "./sourceAndLayer/shizuokaMegane";
import shizuokaShohanShinrinbo5Jou from "./sourceAndLayer/shizuokaShohanShinrinbo5Jou";
import {
  shizuokaShohanShinrinbo2minus5Jou,
  shizuokaShohanShinrinboNotShinrin_0,
  shizuokaShohanShinrinboNotShinrin_9xx
} from "./sourceAndLayer/shizuokaShohanShinrinbo-Other";
import shizuokaShinrinNoShurui from "./sourceAndLayer/shizuokaShinrinNoShurui";
import shizuokaShinrinboLayers_Rinshu from "./sourceAndLayer/shizuokaShinrinboLayers-Rinshu";
import shizuokaShinrinboLayers_Segyoushu from "./sourceAndLayer/shizuokaShinrinboLayers-Segyoushu";
import shizuokaShinrinboLayers_Zoning from "./sourceAndLayer/shizuokaShinrinboLayers-Zoning";
import shizuokaShinrinboLayers_MoriNoChikara from "./sourceAndLayer/shizuokaShinrinboLayers-MoriNoChikara";
import shizuokaMoriNoChikara from "./sourceAndLayer/shizuokaMoriNoChikara";
import shizuokaShinrinSeibiJigyou from "./sourceAndLayer/shizuokaShinrinSeibiJigyou";
import shizuokaKeieiKeikaku from "./sourceAndLayer/shizuokaKeieiKeikaku";
import shizuokaRinchiKaihatsuKyoka from "./sourceAndLayer/shizuokaRinchiKaihatsuKyoka";
import shizuokaRinchiKaihatsuRenrakuChousei from "./sourceAndLayer/shizuokaRinchiKaihatsuRenrakuChousei";
import shizuokaRindo from "./sourceAndLayer/shizuokaRindo";
import shizuokaKokuyurinSource from "./sourceAndLayer/shizuokaKokuyurinSource";
import gsiVectorTile from "./sourceAndLayer/gsiVectorTile";
import sky from "./sourceAndLayer/sky";

import shizuokaShinrinboLayers_RinshuRaster from "./sourceAndLayer/shizuokaShinrinboLayers-Rinshu-raster";
import shizuokaShinrinboLayers_SegyoushuRaster from "./sourceAndLayer/shizuokaShinrinboLayers-Segyoushu-raster";
import shizuokaShinrinboLayers_ZoningRaster from "./sourceAndLayer/shizuokaShinrinboLayers-Zoning-raster";
import shizuokaShinrinboLayers_MoriNoChikaraRaster from "./sourceAndLayer/shizuokaShinrinboLayers-MoriNoChikara-raster";


const HIDDEN_ITEM = true;

export default {
  namespaced: true,
  mutations: {
    updateFeatureMenu(state, newMenuItems) {
      state.menuItems = newMenuItems;
    }
  },
  getters: {
    menuItems: state => {
      return state.menuItems;
    }
  },
  state: {
    sources: {
      // 市町村境界
      shizuokaCityEdgeSource: shizuokaCityEdge.source,
      // 林班
      shizuokaRinpanSource: shizuokaRinpan.source,
      // 準林班
      shizuokaJunRinpanSource: shizuokaJunRinpan.source,
      // 小班(メガネ)
      shizuokaMeganeSource: shizuokaMegane.source,
      // 小班森林簿-５条(共通)
      shizuokaShohanShinrinbo5JouSource: shizuokaShohanShinrinbo5Jou.source,
      // 小班森林簿-２－５条
      shizuokaShohanShinrinbo2minus5JouSource: shizuokaShohanShinrinbo2minus5Jou.source,
      // 小班森林簿-森林以外(小班親番=0)
      shizuokaShohanShinrinboNotShinrin_0Source: shizuokaShohanShinrinboNotShinrin_0.source,
      // 小班森林簿-森林以外(小班親番=9xx)
      shizuokaShohanShinrinboNotShinrin_9xxSource: shizuokaShohanShinrinboNotShinrin_9xx.source,
      // 森の力(実施箇所)
      shizuokaMoriNoChikaraSource: shizuokaMoriNoChikara.source,
      // 森林整備事業
      shizuokaShinrinSeibiJigyouSource: shizuokaShinrinSeibiJigyou.source,
      // 林道
      shizuokaRindoSource: shizuokaRindo.source,
      // 経営計画
      shizuokaKeieiKeikakuSource: shizuokaKeieiKeikaku.source,
      // 林地開発 / 許可
      shizuokaRinchiKaihatsuKyokaSource: shizuokaRinchiKaihatsuKyoka.source,
      // 林地開発 / 連絡調整
      shizuokaRinchiKaihatsuRenrakuChouseiSource: shizuokaRinchiKaihatsuRenrakuChousei.source,
      // 国有林
      shizuokaKokuyurinSource: shizuokaKokuyurinSource.source,
      // 地理院ベクトルタイル 海、川、湖、名称など
      gsiVectorTileSource: gsiVectorTile.source,
      // 森林の種類（ラスター）
      shizuokaShohanShinrinbo5Jou_Rinshu_人工林: shizuokaShinrinboLayers_RinshuRaster.layers[0].metadata.source,
      shizuokaShohanShinrinbo5Jou_Rinshu_天然林: shizuokaShinrinboLayers_RinshuRaster.layers[1].metadata.source,
      shizuokaShohanShinrinbo5Jou_Rinshu_竹林: shizuokaShinrinboLayers_RinshuRaster.layers[2].metadata.source,
      shizuokaShohanShinrinbo5Jou_Rinshu_伐採跡地等: shizuokaShinrinboLayers_RinshuRaster.layers[3].metadata.source,
      // 施業種（ラスター）
      shizuokaShohanShinrinbo5Jou_Segyoushu_通常伐期: shizuokaShinrinboLayers_SegyoushuRaster.layers[0].metadata.source,
      shizuokaShohanShinrinbo5Jou_Segyoushu_伐期の延長: shizuokaShinrinboLayers_SegyoushuRaster.layers[1].metadata.source,
      shizuokaShohanShinrinbo5Jou_Segyoushu_長伐期: shizuokaShinrinboLayers_SegyoushuRaster.layers[2].metadata.source,
      shizuokaShohanShinrinbo5Jou_Segyoushu_複層林: shizuokaShinrinboLayers_SegyoushuRaster.layers[3].metadata.source,
      shizuokaShohanShinrinbo5Jou_Segyoushu_複層林_択伐: shizuokaShinrinboLayers_SegyoushuRaster.layers[4].metadata.source,
      shizuokaShohanShinrinbo5Jou_Segyoushu_特定広葉樹: shizuokaShinrinboLayers_SegyoushuRaster.layers[5].metadata.source,
      // ゾーニング（ラスター）
      shizuokaShohanShinrinbo5Jou_Zoning_水源涵養機能維持増進: shizuokaShinrinboLayers_ZoningRaster.layers[0].metadata.source,
      shizuokaShohanShinrinbo5Jou_Zoning_山地災害防止: shizuokaShinrinboLayers_ZoningRaster.layers[1].metadata.source,
      shizuokaShohanShinrinbo5Jou_Zoning_快適環境形式機能: shizuokaShinrinboLayers_ZoningRaster.layers[2].metadata.source,
      shizuokaShohanShinrinbo5Jou_Zoning_保健文化機能維持増進: shizuokaShinrinboLayers_ZoningRaster.layers[3].metadata.source,
      shizuokaShohanShinrinbo5Jou_Zoning_木材生産機能維持増進: shizuokaShinrinboLayers_ZoningRaster.layers[4].metadata.source,
      // 森の力 再生事業対象地（ラスター）
      shizuokaShohanShinrinbo5Jou_MoriNoChikara_再生事業対象地: shizuokaShinrinboLayers_MoriNoChikaraRaster.layers[0].metadata.source,
      shizuokaShohanShinrinbo5Jou_MoriNoChikara_再生事業対象地_67以外: shizuokaShinrinboLayers_MoriNoChikaraRaster.layers[1].metadata.source,
    },
    layers: [].concat(
      // レイヤーの描画順はこのリストで決まります
      // (下に行くほど後から描画されます)
      shizuokaShinrinNoShurui.layers, // 森林の種類 / 2-5条森林
      shizuokaShinrinboLayers_RinshuRaster.layers, // 森林簿 / 林種 ラスター
      shizuokaShinrinboLayers_Rinshu.layers, // 森林簿 / 林種
      shizuokaShinrinboLayers_Segyoushu.layers, // 森林簿 / 施業種
      shizuokaShinrinboLayers_SegyoushuRaster.layers, // 森林簿 / 施業種 ラスター
      shizuokaShinrinboLayers_Zoning.layers, // 森林簿 / ゾーニング
      shizuokaShinrinboLayers_ZoningRaster.layers, // 森林簿 / ゾーニング ラスター
      shizuokaShinrinboLayers_MoriNoChikara.layers, // 森林簿 / 森の力 再生事業対象地
      shizuokaShinrinboLayers_MoriNoChikaraRaster.layers, // 森林簿 / 森の力 再生事業対象地 ラスター
      shizuokaRinchiKaihatsuKyoka.layers, // 林地開発 / 許可
      shizuokaRinchiKaihatsuRenrakuChousei.layers, // 林地開発 / 連絡調整
      shizuokaKeieiKeikaku.layers, // 経営計画
      shizuokaShinrinSeibiJigyou.layers, // 森林整備事業
      shizuokaMoriNoChikara.layers, // 森の力 実施箇所
      shizuokaCityEdge.layers, // 市町村境界
      shizuokaRindo.layers, //林道
      shizuokaShohan.layers, // 小班
      shizuokaMegane.layers, // 小班(メガネ)
      shizuokaJunRinpan.layers, // 準林班
      shizuokaRinpan.layers, // 林班
      gsiVectorTile.layers, // 海、川、湖、名称など
      sky.layers,
    ),
    menuItems: [],
    initialMenuItems: [
      // メニューの並び順はこのリストで決まります
      {
        group: "",
        title: "市町村境界",
        isChecked: true,
        relatedLayerId: [
          "shizuoka-city-edge",
          "shizuoka-city-edge-halo",
          "shizuoka-city-edge-label"
        ],
        legend: {
          layerId: "shizuoka-city-edge",
          type: "border",
          size: "2px"
        }
      },
      {
        group: "",
        title: "林班",
        isChecked: true,
        relatedLayerId: [
          "shizuoka-rinpan",
          "shizuoka-rinpan-halo",
          "shizuoka-rinpan-label"
        ],
        legend: {
          layerId: "shizuoka-rinpan",
          type: "border",
          size: "1px"
        }
      },
      {
        group: "",
        title: "準林班",
        isChecked: true,
        relatedLayerId: [
          "shizuoka-jun-rinpan",
          "shizuoka-jun-rinpan-halo",
          "shizuoka-jun-rinpan-label"
        ],
        legend: {
          layerId: "shizuoka-jun-rinpan",
          type: "border",
          size: "2px"
        }
      },
      {
        group: "",
        title: "小班（５条森林）",
        isChecked: true,
        relatedLayerId: [
          "shizuoka-shohan",
          "shizuoka-shohan-fill",
          "shizuoka-shohan-halo",
          "shizuoka-shohan-label",
          "shizuoka-megane"
        ],
        legend: {
          layerId: "shizuoka-shohan-fill",
          type: "fill-border",
          borderColor: "blue",
          size: "2px",
        }
      },
      {
        group: "森林の種類",
        title: "国有林（参考）",
        isChecked: true,
        relatedLayerId: [
          "shizuoka-森林の種類-国有林"
        ],
        legend: {
          layerId: "shizuoka-森林の種類-国有林",
          type: "fill"
        }
      },
      {
        HIDDEN: HIDDEN_ITEM,
        group: "森林の種類",
        title: "５条森林 ※",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-森林の種類-５条森林"
        ]
      },
      {
        group: "森林の種類",
        title: "２－５条森林",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-森林の種類-２－５条森林"
        ],
        legend: {
          layerId: "shizuoka-森林の種類-２－５条森林",
          type: "fill"
        }
      },
      {
        HIDDEN: HIDDEN_ITEM,
        group: "森林の種類",
        title: "森林以外 (000) ※",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-森林の種類-森林以外-000"
        ]
      },
      {
        HIDDEN: HIDDEN_ITEM,
        group: "森林の種類",
        title: "森林以外 (9xx) ※",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-森林の種類-森林以外-9xx"
        ]
      },
      {
        group: "林種",
        title: "人工林",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-shinrinbo-林種-人工林",
          "shizuoka-shinrinbo-林種-raster-人工林"
        ],
        legend: {
          layerId: "shizuoka-shinrinbo-林種-人工林",
          type: "fill"
        }
      },
      {
        group: "林種",
        title: "天然林",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-shinrinbo-林種-天然林",
          "shizuoka-shinrinbo-林種-raster-天然林"
        ],
        legend: {
          layerId: "shizuoka-shinrinbo-林種-天然林",
          type: "fill"
        }
      },
      {
        group: "林種",
        title: "竹林",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-shinrinbo-林種-竹林",
          "shizuoka-shinrinbo-林種-raster-竹林"
        ],
        legend: {
          layerId: "shizuoka-shinrinbo-林種-竹林",
          type: "fill"
        }
      },
      {
        group: "林種",
        title: "伐採跡地等",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-shinrinbo-林種-伐採跡地等",
          "shizuoka-shinrinbo-林種-raster-伐採跡地等"
        ],
        legend: {
          layerId: "shizuoka-shinrinbo-林種-伐採跡地等",
          type: "fill"
        }
      },
      {
        group: "施業種",
        title: "通常伐期",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-shinrinbo-施業種-通常伐期",
          "shizuoka-shinrinbo-施業種-raster-通常伐期"
        ],
        legend: {
          layerId: "shizuoka-shinrinbo-施業種-通常伐期",
          type: "fill"
        }
      },
      {
        group: "施業種",
        title: "伐期の延長",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-shinrinbo-施業種-伐期の延長",
          "shizuoka-shinrinbo-施業種-raster-伐期の延長"
        ],
        legend: {
          layerId: "shizuoka-shinrinbo-施業種-伐期の延長",
          type: "fill"
        }
      },
      {
        group: "施業種",
        title: "長伐期",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-shinrinbo-施業種-長伐期",
          "shizuoka-shinrinbo-施業種-raster-長伐期"
        ],
        legend: {
          layerId: "shizuoka-shinrinbo-施業種-長伐期",
          type: "fill"
        }
      },
      {
        group: "施業種",
        title: "複層林",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-shinrinbo-施業種-複層林",
          "shizuoka-shinrinbo-施業種-raster-複層林"
        ],
        legend: {
          layerId: "shizuoka-shinrinbo-施業種-複層林",
          type: "fill"
        }
      },
      {
        group: "施業種",
        title: "複層林（択伐）",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-shinrinbo-施業種-複層林（択伐）",
          "shizuoka-shinrinbo-施業種-raster-複層林（択伐）"
        ],
        legend: {
          layerId: "shizuoka-shinrinbo-施業種-複層林（択伐）",
          type: "fill"
        }
      },
      {
        group: "施業種",
        title: "特定広葉樹",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-shinrinbo-施業種-特定広葉樹",
          "shizuoka-shinrinbo-施業種-raster-特定広葉樹"
        ],
        legend: {
          layerId: "shizuoka-shinrinbo-施業種-特定広葉樹",
          type: "fill"
        }
      },
      {
        group: "ゾーニング",
        title: "水源涵養機能維持増進",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-shinrinbo-ゾーニング-水源涵養機能維持増進",
          "shizuoka-shinrinbo-ゾーニング-raster-水源涵養機能維持増進"
        ],
        legend: {
          layerId: "shizuoka-shinrinbo-ゾーニング-水源涵養機能維持増進",
          type: "fill"
        }
      },
      {
        group: "ゾーニング",
        title: "山地災害防止",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-shinrinbo-ゾーニング-山地災害防止",
          "shizuoka-shinrinbo-ゾーニング-raster-山地災害防止"
        ],
        legend: {
          layerId: "shizuoka-shinrinbo-ゾーニング-山地災害防止",
          type: "fill"
        }
      },
      {
        group: "ゾーニング",
        title: "快適環境形式機能",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-shinrinbo-ゾーニング-快適環境形式機能",
          "shizuoka-shinrinbo-ゾーニング-raster-快適環境形式機能"
        ],
        legend: {
          layerId: "shizuoka-shinrinbo-ゾーニング-快適環境形式機能",
          type: "fill"
        }
      },
      {
        group: "ゾーニング",
        title: "保健文化機能維持増進",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-shinrinbo-ゾーニング-保健文化機能維持増進",
          "shizuoka-shinrinbo-ゾーニング-raster-保健文化機能維持増進"
        ],
        legend: {
          layerId: "shizuoka-shinrinbo-ゾーニング-保健文化機能維持増進",
          type: "fill"
        }
      },
      {
        group: "ゾーニング",
        title: "木材生産機能維持増進",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-shinrinbo-ゾーニング-木材生産機能維持増進",
          "shizuoka-shinrinbo-ゾーニング-raster-木材生産機能維持増進"
        ],
        legend: {
          layerId: "shizuoka-shinrinbo-ゾーニング-木材生産機能維持増進",
          type: "fill"
        }
      },
      {
        group: "森の力",
        title: "再生事業対象地",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-shinrinbo-森の力-再生事業対象地",
          "shizuoka-shinrinbo-森の力-raster-再生事業対象地"
        ],
        legend: {
          layerId: "shizuoka-shinrinbo-森の力-再生事業対象地",
          type: "fill"
        }
      },
      {
        HIDDEN: HIDDEN_ITEM,
        group: "森の力",
        title: "再生事業対象地(6,7以外) ※",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-shinrinbo-森の力-再生事業対象地（6,7以外）",
          "shizuoka-shinrinbo-森の力-raster-再生事業対象地（6,7以外）"
        ]
      },
      {
        group: "森の力",
        title: "実施箇所",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-森の力-実施箇所"
        ],
        legend: {
          layerId: "shizuoka-森の力-実施箇所",
          type: "fill"
        }
      },
      {
        group: "森林整備事業",
        title: "森林整備事業",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-森林整備事業"
        ],
        legend: {
          layerId: "shizuoka-森林整備事業",
          type: "fill"
        }
      },
      {
        group: "林道",
        title: "林道",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-rindo",
          "shizuoka-rindo-halo"
        ],
        legend: {
          layerId: "shizuoka-rindo",
          type: "line",
          size: "2px"
        }
      },
      {
        group: "経営計画",
        title: "森林経営計画作成箇所",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-経営計画-森林経営計画作成箇所"
        ],
        legend: {
          layerId: "shizuoka-経営計画-森林経営計画作成箇所",
          type: "fill"
        }
      },
      {
        group: "林地開発",
        title: "許可",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-林地開発-許可"
        ],
        legend: {
          layerId: "shizuoka-林地開発-許可",
          type: "fill"
        }
      },
      {
        group: "林地開発",
        title: "連絡調整",
        isChecked: false,
        relatedLayerId: [
          "shizuoka-林地開発-連絡調整"
        ],
        legend: {
          layerId: "shizuoka-林地開発-連絡調整",
          type: "fill"
        }
      },
    ]
  } // end of state
};
