<template>
  <div id="app">
    <router-view />
    <LoadingOverlay />
  </div>
</template>

<script>
import LoadingOverlay from '@/components/LoadingOverlay.vue';

export default {
  name: "App",
  components: {
    LoadingOverlay
  },
  data: () => ({
    //
  })
};
</script>

<style>
#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
