const packageJson = require("/package.json");

const tilesPath =
    process.env.VUE_APP_SHIZUOKA_MVT_ENDPOINT +
    "/MAGIS.KOKUYURIN/{z}/{x}/{y}.pbf" +
    "?" + packageJson.lastupdate

const shizuokaKokuyurin = {
  source: {
    type: "vector",
    tiles: [tilesPath],
    minzoom: 4,
    maxzoom: 20
  }
};

export default shizuokaKokuyurin;
