const packageJson = require("/package.json");

const GROUP_SOURCE = "shizuokaShohanShinrinbo5JouSource";
const GROUP_SOURCE_LAYER = "MAGIS.SHOHAN_SHINRINBO.5JOU";
const GROUP_MIN_ZOOM = 12.5;
const GROUP_MAX_ZOOM = 20;
const GROUP_BASE_OPACITY = 0.5;

// 小班森林簿 / 施業種レイヤー群
const layers = [];

layers.push(
  {
    id: "shizuoka-shinrinbo-施業種-通常伐期",
    type: "fill",
    source: GROUP_SOURCE,
    "source-layer": GROUP_SOURCE_LAYER,
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    filter: [
      "==",
      "ゾーニング施業種",
      "1"
    ],
    paint: {
      "fill-color": "#CCFFCC"
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-施業種-伐期の延長",
    type: "fill",
    source: GROUP_SOURCE,
    "source-layer": GROUP_SOURCE_LAYER,
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    filter: [
      "==",
      "ゾーニング施業種",
      "2"
    ],
    paint: {
      "fill-color": "#CCFFFF"
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-施業種-長伐期",
    type: "fill",
    source: GROUP_SOURCE,
    "source-layer": GROUP_SOURCE_LAYER,
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    filter: [
      "==",
      "ゾーニング施業種",
      "3"
    ],
    paint: {
      "fill-color": "#99CCFF"
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-施業種-複層林",
    type: "fill",
    source: GROUP_SOURCE,
    "source-layer": GROUP_SOURCE_LAYER,
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    filter: [
      "==",
      "ゾーニング施業種",
      "4"
    ],
    paint: {
      "fill-color": "#CC99FF"
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-施業種-複層林（択伐）",
    type: "fill",
    source: GROUP_SOURCE,
    "source-layer": GROUP_SOURCE_LAYER,
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    filter: [
      "==",
      "ゾーニング施業種",
      "5"
    ],
    paint: {
      "fill-color": "#FFCC67"
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-施業種-特定広葉樹",
    type: "fill",
    source: GROUP_SOURCE,
    "source-layer": GROUP_SOURCE_LAYER,
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    filter: [
      "==",
      "ゾーニング施業種",
      "6"
    ],
    paint: {
      "fill-color": "#CC9900"
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
);

const shizuokaShinrinboLayers_Segyoushu = {
  layers: layers
};

export default shizuokaShinrinboLayers_Segyoushu;
