<template>
  <ManagementPane />
</template>

<script>
import ManagementPane from "../components/ManagementPane";

export default {
  name: "Management",
  components: {
    ManagementPane
  }
};
</script>
