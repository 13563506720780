const packageJson = require('/package.json');

// 準林班レイヤー一覧
const layers = [];

// 準林班
layers.push(
  // ポリゴンの外周(太)
  {
    id: "shizuoka-jun-rinpan-halo",
    type: "line",
    source: "shizuokaJunRinpanSource",
    "source-layer": "MAGIS.JUNRINPAN",
    minzoom: 13,
    maxzoom: 20,
    paint: {
      "line-color": "black",
      "line-width": 5
    },
    metadata: {
      baseOpacity: 0.5
    }
  },
  // ポリゴンの外周(細)
  {
    id: "shizuoka-jun-rinpan",
    type: "line",
    source: "shizuokaJunRinpanSource",
    "source-layer": "MAGIS.JUNRINPAN",
    minzoom: 13,
    maxzoom: 20,
    paint: {
      "line-color": "silver",
      "line-width": 2,
    },
    metadata: {
      baseOpacity: 0.5
    }
  },
  // ラベル
  {
    id: "shizuoka-jun-rinpan-label",
    type: "symbol",
    source: "shizuokaJunRinpanSource",
    "source-layer": "MAGIS.JUNRINPAN",
    minzoom: 14,
    maxzoom: 20,
    paint: {
      "text-color": "silver",
      "text-halo-color": "black",
      "text-halo-width": 2
    },
    layout: {
      "text-field": ['get', 'NAME'],
      'text-size': 24,
      'text-anchor': 'center',
      'text-justify': 'center',
      "text-allow-overlap": true,
      "text-ignore-placement": false,
      "text-variable-anchor": ["top", "bottom"]
    },
    metadata: {
      baseOpacity: 1.0
    }
  },
);

const tilesPath =
    process.env.VUE_APP_SHIZUOKA_MVT_ENDPOINT +
    "/MAGIS.JUNRINPAN/{z}/{x}/{y}.pbf" +
    "?" + packageJson.lastupdate

const shizuokaJunRinpan = {
  layers: layers,
  source: {
    type: "vector",
    tiles: [tilesPath],
    minzoom: 4,
    maxzoom: 18
  }
};

export default shizuokaJunRinpan;
