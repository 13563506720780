export default {
  namespaced: true,
  state: {
    lng: null,
    lat: null,
    zoom: null,
    exaggeration: 1
  },
  mutations: {
    storeLng(state, payload) {
      state.lng = payload.lng;
    },
    storeLat(state, payload) {
      state.lat = payload.lat;
    },
    storeZoom(state, payload) {
      state.zoom = payload.zoom;
    },
    storeLngLat(state, payload) {
      state.lng = payload.lng;
      state.lat = payload.lat;
    },
    storeLngLatZoom(state, payload) {
      state.lng = payload.lng;
      state.lat = payload.lat;
      state.zoom = payload.zoom;
    },
    storeExaggeration(state, payload) {
      state.exaggeration = payload;
    },
    jumpToHome(state) {
      state.lng = this.getters["const/homeLng"];
      state.lat = this.getters["const/homeLat"];
      state.zoom = this.getters["const/homeZoom"];
    },
    jumpToMyLocation(state, payload) {
      state.lng = payload.lng;
      state.lat = payload.lat;
      state.zoom = payload.zoom;
    },
    jumpToInitialLocation(state, payload) {
      state.lng = payload.lng;
      state.lat = payload.lat;
      state.zoom = this.getters["const/myLocationZoom"];
    },
    jumpToFeatureLocation(state, payload) {
      state.lng = payload.lng;
      state.lat = payload.lat;
      state.zoom = payload.zoom;
    }
  },
  actions: {
    setMyLocation(state) {
      // 応用地質本社
      const OYO_LNG = 139.7655566;
      const OYO_LAT = 35.6931327;
      if ("geolocation" in navigator) {
        const options = {
          enableHighAccuracy: true,
          timeout: 2000,
          maximumAge: 0
        };
        const success = pos => {
          const coords = pos.coords;
          state.lng = coords.longitude;
          state.lat = coords.latitude;
          state.zoom = this.getters["const/myLocationZoom"];
          this.commit("map/jumpToMyLocation", state, coords);
        };
        const error = err => {
          console.warn(`ERROR(${err.code}): ${err.message}`);
          const coords = { longitude: OYO_LNG, latitude: OYO_LAT };
          state.lng = OYO_LNG;
          state.lat = OYO_LAT;
          state.zoom = this.getters["const/myLocationZoom"];
          this.commit("map/jumpToMyLocation", state, coords);
          console.log(`jump to default position.`);
        };
        navigator.geolocation.getCurrentPosition(success, error, options);
      } else {
        console.warn(`WARN: geolocation IS NOT available.`);
        const coords = { longitude: OYO_LNG, latitude: OYO_LAT };
        state.lng = OYO_LNG;
        state.lat = OYO_LAT;
        state.zoom = this.getters["const/myLocationZoom"];
        this.commit("map/jumpToMyLocation", state, coords);
        console.log(`jump to default position.`);
      }
    },
    setInitialLocation() {
      this.commit("map/jumpToHome");
      console.log(`jump to default position.`);
    }
  }
};
