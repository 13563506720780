const packageJson = require("/package.json");

const GROUP_SOURCE = "shizuokaShohanShinrinbo5JouSource";
const GROUP_SOURCE_LAYER = "MAGIS.SHOHAN_SHINRINBO.5JOU";
const GROUP_MIN_ZOOM = 14;
const GROUP_MAX_ZOOM = 20;

// 小班レイヤー一覧
const layers = [];

// 小班
layers.push(
  // ポリゴンの面
  {
    id: "shizuoka-shohan-fill",
    type: "fill",
    source: GROUP_SOURCE,
    "source-layer": GROUP_SOURCE_LAYER,
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    paint: {
      "fill-color": "#0066BB",
    },
    metadata: {
      baseOpacity: 0.2
    }
  },
  // ポリゴンの面(ハイライト)
  {
    id: "shizuoka-shohan-hilight",
    type: "fill",
    source: GROUP_SOURCE,
    "source-layer": GROUP_SOURCE_LAYER,
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    filter: [
      "all",
      false
    ],
    paint: {
      "fill-color": "rgba(255, 255, 0, 0.4)",
    },
    layout: {
      visibility: "none"
    },
    metadata: {
      baseOpacity: 0.5
    }
  },
  // ポリゴンの外周(太)
  {
    id: "shizuoka-shohan-halo",
    type: "line",
    source: GROUP_SOURCE,
    "source-layer": GROUP_SOURCE_LAYER,
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    paint: {
      "line-color": "blue",
      "line-width": [
        "step",
        ["zoom"],
        2.5,      // Default
        15.5, 3   // Zoom level threshold & line-width after the threshold
      ]
    },
    metadata: {
      baseOpacity: 0.5
    }
  },
  // ポリゴンの外周(細)
  {
    id: "shizuoka-shohan",
    type: "line",
    source: GROUP_SOURCE,
    "source-layer": GROUP_SOURCE_LAYER,
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    paint: {
      "line-color": "greenyellow",
      "line-width": [
        "step",
        ["zoom"],
        0.5,      // Default
        15.5, 1   // Zoom level threshold & line-width after the threshold
      ]
    },
    metadata: {
      baseOpacity: 0.5
    }
  },
  // ラベル
  {
    id: "shizuoka-shohan-label",
    type: "symbol",
    source: GROUP_SOURCE,
    "source-layer": GROUP_SOURCE_LAYER,
    minzoom: 14.5,
    maxzoom: GROUP_MAX_ZOOM,
    paint: {
      "text-color": "greenyellow",
      "text-halo-color": "blue",
      "text-halo-width": 1.5
    },
    layout: {
      "text-field": ["get", "小班ラベル"],
      "text-size": [
        "step",
        ["zoom"],
        10,         // Default text-size
        15.0, 12,   // Zoom level threshold & text-size after the threshold
        15.5, 14,
        16.0, 16,
        20.0, 16
      ],
      "text-anchor": "center",
      "text-justify": "center",
      "text-allow-overlap": true
    },
    metadata: {
      baseOpacity: 1.0
    }
  },
);


const shizuokaShohan = {
  layers: layers,
};

export default shizuokaShohan;
