import Vue from "vue";
import router from "./router";
import store from "./store";
import Axios from "axios";
import VueAxios from "vue-axios";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import SetInterval from "@/plugins/SetInterval";
import App from "./App.vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

import "mapbox-gl/dist/mapbox-gl.css";

Vue.use(VueAxios, Axios);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(SetInterval);
Vue.use(CoolLightBox);

if (process.env.NODE_ENV === 'production') {
  Vue.config.devtools = false;
  Vue.config.debug = false;
  Vue.config.silent = true;
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
