<template>
  <TopPagePane />
</template>

<script>
import TopPagePane from "../components/TopPagePane";

export default {
  name: "TopPage",
  components: {
    TopPagePane
  }
};
</script>
