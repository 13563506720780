const packageJson = require('/package.json');

// メガネレイヤー一覧
const layers = [];

layers.push(
  // ライン
  // 小班のrelatedLayerにしています
  {
    id: "shizuoka-megane",
    type: "line",
    source: "shizuokaMeganeSource",
    "source-layer": "MAGIS.MEGANE",
    minzoom: 14,
    maxzoom: 20,
    paint: {
      "line-color": "orange",
      "line-width": 3,
    },
    metadata: {
      baseOpacity: 0.7
    }
  },
);

const tilesPath =
    process.env.VUE_APP_SHIZUOKA_MVT_ENDPOINT +
    "/MAGIS.MEGANE/{z}/{x}/{y}.pbf" +
    "?" + packageJson.lastupdate

const shizuokaMegane = {
  layers: layers,
  source: {
    type: "vector",
    tiles: [tilesPath],
    minzoom: 4,
    maxzoom: 20
  }
};

export default shizuokaMegane;
