const packageJson = require('/package.json');

const GROUP_SOURCE = "shizuokaShohanShinrinbo5JouSource";
const GROUP_SOURCE_LAYER = "MAGIS.SHOHAN_SHINRINBO.5JOU";
const GROUP_MIN_ZOOM = 12.5;
const GROUP_MAX_ZOOM = 20;
const GROUP_BASE_OPACITY = 0.5;

// 小班森林簿 / ゾーニングレイヤー群
const layers = [];

layers.push(
  {
    id: "shizuoka-shinrinbo-ゾーニング-水源涵養機能維持増進",
    type: "fill",
    source: GROUP_SOURCE,
    "source-layer": GROUP_SOURCE_LAYER,
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    filter: [
      "==",
      "ゾーニング機能_水源涵養",
      "1"
    ],
    paint: {
      "fill-color": "#0000FF"
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-ゾーニング-山地災害防止",
    type: "fill",
    source: GROUP_SOURCE,
    "source-layer": GROUP_SOURCE_LAYER,
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    filter: [
      "==",
      "ゾーニング機能_山地災害防止",
      "1"
    ],
    paint: {
      "fill-color": "#FF6600"
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-ゾーニング-快適環境形式機能",
    type: "fill",
    source: GROUP_SOURCE,
    "source-layer": GROUP_SOURCE_LAYER,
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    filter: [
      "==",
      "ゾーニング機能_快適環境",
      "1"
    ],
    paint: {
      "fill-color": "#800080"
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-ゾーニング-保健文化機能維持増進",
    type: "fill",
    source: GROUP_SOURCE,
    "source-layer": GROUP_SOURCE_LAYER,
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    filter: [
      "==",
      "ゾーニング機能_保健",
      "1"
    ],
    paint: {
      "fill-color": "#66FF33"
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-ゾーニング-木材生産機能維持増進",
    type: "fill",
    source: GROUP_SOURCE,
    "source-layer": GROUP_SOURCE_LAYER,
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    filter: [
      "==",
      "ゾーニング機能_木材生産",
      "1"
    ],
    paint: {
      "fill-color": "#CC9900"
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
);

const shizuokaShinrinboLayers_Zoninng = {
  layers: layers
};

export default shizuokaShinrinboLayers_Zoninng;
