<template>
  <transition name="fade" mode="out-in">
    <div
      v-if="isLoading"
      class="loading-overlay"
    >
      <div class="spinner"></div>
      <span>データを取得しています……</span>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: "LoadingOverlay",
  computed: {
    ...mapState({
      isLoading: state => state.isLoading
    })
  }
};
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loading-overlay span {
  color: white;
  font-size: 1.5em;
  font-weight: bold;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #fff;
  animation: spin 0.7s linear infinite;
  margin: 16px;
  width: 40px;
  height: 40px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
