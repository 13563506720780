export default {
  namespaced: true,
  state: {
    decimalLatLng: [],
    decimalLngLat: [],
    dmsLatLng: [],
    dmsLngLat: []
  },
  mutations: {
    setDecimalLatLng(state, data) {
      state.decimalLatLng = data;
    },
    setDecimalLngLat(state, data) {
      state.decimalLngLat = data;
    },
    setDmsLatLng(state, data) {
      state.dmsLatLng = data;
    },
    setDmsLngLat(state, data) {
      state.dmsLngLat = data;
    }
  },
  getters: {
    decimalLatLng: state => {
      return state.decimalLatLng;
    },
    decimalLngLat: state => {
      return state.decimalLngLat;
    },
    dmsLatLng: state => {
      return state.dmsLatLng;
    },
    dmsLngLat: state => {
      return state.dmsLngLat;
    }
  }
};
